<template>
    <div class="nav-search-report-link w-100 p-0 m-0">
      <NavSearchLink
        class="px-0"
        :indexGroup="indexGroup"
        :navSearchItem="navSearchItem"
        :target="target"
        :select_first_link="select_first_link"
        :first_link_index="first_link_index"
        :navigateOnClicked="false"
        :closeOnClicked="false"
        @clicked="createReport"
      />
      <ReportGeneratorModal
        v-if="showModal"
        @closed="showModal = false"
        :route="route"
      />
      <!-- Advanced Filters when applicable -->
      <ReportAdvancedFiltersModal
        v-if="report && showAdvancedFiltersModal"
        :report="report"
        @closed="showAdvancedFiltersModal = false"
      />
    </div>
</template>
<script>
import NavSearchLink from "@/vue/components/nav/NavSearchLink.vue";
import WorksWithV3Reports from "@/vue/mixins/reports/WorksWithV3Reports.vue";
import ReportAdvancedFiltersModal from "@/vue/components/reports/ReportAdvancedFiltersModal.vue";
import ReportAdvancedFilters from "@/vue/components/reports/ReportAdvancedFilters.vue";
import ReportGeneratorModal from "@/vue/components/reports/ReportGeneratorModal.vue";

export default {
  components: {ReportAdvancedFiltersModal, ReportAdvancedFilters, ReportGeneratorModal, NavSearchLink},

  props: {
    'triggerNavigation': {required: true}, // @enter

    // To make sure the Links are grouped properly, we prepend the index with a number
    // Otherwise the keyoard navigation might start in the middle
    'indexGroup': {default: 0},

    // Contains a title, path, visible (boolean), and optionally, right_title property
    'navSearchItem': {},

    // Set to '_blank' to open in a new window
    'target': {default: '_self'},

    // Use these to auto-select the first link
    'select_first_link': {default: false},
    'first_link_index': {default: -1},
  },

  mixins: [
    WorksWithV3Reports,
  ],

  data: function () {
    return {
      showModal: false,
      showAdvancedFiltersModal: false,
      report: null,

      // Nodig om op de report-id pagina een nieuwe te genereren
      alwaysShowAdvancedFilterModal: true,
    };
  },

  computed: {
    route() {
      return this.navSearchItem.path;
    },
    uid() {
      return this._.uid;
    },
    selectedNavSearchLinkUid: {
      get() {
        return this.$store.state.settings.navigation.selectedNavSearchLinkUid;
      },
      set(value) {
        this.$store.commit('settings/navigation/setSelectedNavSearchLinkUid', value);
      }
    },
    isSelected() {
      // We moeten hier -1 doen omdat het de parent is van de selectedNavSearchLink
      return this.uid === (this.selectedNavSearchLinkUid - 1);
    },
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    triggerNavigation() {
      if (this.isSelected) {
        this.createReport();
      }
    }
  }
};
</script>
<style lang="scss">
/** Nodig om de styles te counteren voor de advanced filter modal, aangezien deze hier nested is in NavSearch **/
#top-nav li.nav-item .nav-search-report-link .medimodal .btn {
  padding: 0.3rem 0.75rem;
}

#nav-search .list-group .nav-search-report-link .medimodal {
  .btn-primary, .btn-medimo, .btn:hover {
    color: white;
  }
}

#nav-search .nav-search-report-link .medimodal {
  .medimo-vue-select2 input {
    border: none;
  }

  input {
    border-radius: 3px;
    width: 100%;
    padding-left: 4px;
    min-height: 0;
  }
  svg.svg-inline--fa {
    opacity: 1;
  }
}
</style>
