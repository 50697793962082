<template>
  <medimo-tr @triggered="showEditReadingModal" :inactive="inactive" class="reading-data-table-row">

    <medimo-td column_classes="col col-sm-2">
      <span>{{ parsedDate }}</span>
    </medimo-td>

    <medimo-td column_classes="col col-sm-4">
      <span>{{ reading.bepaling }}</span>
    </medimo-td>

    <medimo-td column_classes="col col-sm-3">
      <span>{{ parsedWaarde }}</span>
      <span v-if="reading.normaalwaardeindicatie" class="badge badge-sm badge-warning ml-1">{{ reading.normaalwaardeindicatie }}</span>
      <medimo-tooltip v-if="reading.opmerking" class="badge badge-sm badge-medimo-50 ml-1" :content="reading.opmerking">i</medimo-tooltip>
    </medimo-td>

    <medimo-td column_classes="col col-sm-3">
      <span>{{ parsedReferentie }}</span>
    </medimo-td>

  </medimo-tr>
</template>

<script>
import Dates from '@/vue/utility/dates';
import MedimoTd from '@/vue/components/general/table/MedimoTd';
import MedimoTr from '@/vue/components/general/table/MedimoTr';
import MedimoTooltip from "@/vue/components/general/MedimoTooltip.vue";

export default {
  emits: ['selected'],
  components: {
    MedimoTooltip,
    MedimoTd,
    MedimoTr,
  },

  props: {
    'reading': { required: true },
    'patient': { required: true },
    inactive: {default: false},
  },

  mixins: [],

  data: function () {
    return {

    };
  },

  computed: {
    parsedDate() {
      return Dates.setDate(this.reading.datum).dutchDate();
    },
    parsedWaarde() {
      return this.reading.uitslag + ' ' + this.reading.eenheid;
    },
    parsedReferentie() {
      return 'Ref.: ' + this.reading.ondergrens + this.parsedStreepje + this.reading.bovengrens + ' ' + this.reading.eenheid;
    },
    parsedStreepje() {
      if (this.reading.ondergrens && this.reading.bovengrens) {
        return ' - ';
      } else {
        return '';
      }
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    showEditReadingModal() {
      this.$emit('selected');
    },
  },

  watch: {
    //
  }
};
</script>
